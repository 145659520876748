import React from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { appTheme } from 'common/src/theme/app';
import {
  GlobalStyle,
  AppWrapper,
  ConditionWrapper,
} from '../containers/App/app.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../containers/App/Navbar';
import Privacy from '../containers/App/Privacy';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Footer from '../containers/App/Footer';
import SEO from '../components/seo';
import { useIntl } from "gatsby-plugin-intl";

const PrivacyPage = () => {
  const intl = useIntl()
  return(
    <ThemeProvider theme={appTheme}>
      <SEO title="Privacy Policy | Touchlessr" />
      <ResetCSS />
      <GlobalStyle />
      <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                  <Navbar lang={intl.locale} hide={true}/>
              </DrawerProvider>
          </Sticky>
          <Privacy />
          <Footer lang={intl.locale}/>
      </AppWrapper>
    </ThemeProvider>
  )
}

export default PrivacyPage;
