import React from 'react';
import Fade from 'react-reveal/Fade';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import SectionHeading from '../SectionHeading';
import SectionWrapper, { ContentWrapper, FaqItem } from './privacy.style';
import { useIntl } from "gatsby-plugin-intl";

const Faq = () => {
  const intl = useIntl();
  return (
    <SectionWrapper id="cookies">
      <Container>
        <Fade up delay={100}>
          <SectionHeading
            title={`${intl.locale ==='en'? 'Privacy Policy': 'Política de Privacidade'}`}
          />
        </Fade>
        <ContentWrapper>
            <Fade key={1} up delay={100}>
              <FaqItem>
                <Text content={intl.formatMessage({id:"privacy_text_1"})} />
                <Text content={intl.formatMessage({id:"privacy_text_2"})} />
                <Text content={intl.formatMessage({id:"privacy_text_3"})} />
                <Text content={intl.formatMessage({id:"privacy_text_4"})} />
                <Text content={intl.formatMessage({id:"privacy_text_5"})} />
              </FaqItem>
            </Fade>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Faq;
